import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import PositionControl from './PositionControl';
import Touch from './PositionControl/Touch';
import styles from './styles.module.scss';

const Controller = ({
  intensity,
  setIntensity,
  positions,
  setPositions,
  isRangeMode,
  setIsRangeMode,
}) => {
  const [values, setValues] = useState({ x: 180, y: 367 });
  const [isTooltipsVisible, setTooltipsVisible] = useState(true);
  const [parent, setParent] = useState(null);
  const [dragging, setDragging] = useState(false);

  useEffect(() => {
    const parentPosition = parent?.getBoundingClientRect();
    if (!parentPosition) {
      return;
    }
    setIntensity(
      (((parentPosition.height - 80 - values.y) * 100) / (parentPosition.height - 80)).toFixed(),
    );
    if (isTooltipsVisible) {
      setTooltipsVisible(false);
    }
  }, [positions, values, isRangeMode]);

  const onDragStart = (e) => {
    if (e.target.className.includes('touch')) {
      setDragging(true);
    }
  };

  const onDragging = (e) => {
    if (dragging) {
      const parentPos = parent.getBoundingClientRect();
      const clientX = e.type === 'touchmove' ? e.touches[0].clientX : e.clientX;
      const clientY = e.type === 'touchmove' ? e.touches[0].clientY : e.clientY;
      const x =
        clientX - parentPos.left > parentPos.width - 130 && isRangeMode
          ? parentPos.width - 130
          : clientX - parentPos.left > parentPos.width - 40
          ? parentPos.width - 40
          : clientX - parentPos.left < 40
          ? 40
          : clientX - parentPos.left > parentPos.width
          ? parentPos.width
          : clientX - parentPos.left;

      const y =
        clientY - parentPos.top > parentPos.height - 80
          ? parentPos.height - 80
          : clientY - parentPos.top < 0
          ? 0
          : clientY - parentPos.top;

      setValues({ x, y });
    }
  };

  const onDragEnd = () => {
    setDragging(false);
  };

  const mobileProps = {
    onTouchEnd: onDragEnd,
    onTouchStart: onDragStart,
    onTouchMove: onDragging,
    onTouchCancel: onDragEnd,
  };
  const desktopProps = {
    onPointerLeave: onDragEnd,
    onPointerDown: onDragStart,
    onPointerMove: onDragging,
    onPointerUp: onDragEnd,
  };
  const dragEventProps = isMobile ? mobileProps : desktopProps;

  return (
    <div>
      <div className={styles.container}>
        <div ref={(ref) => setParent(ref)} className={styles.controller} {...dragEventProps}>
          <Touch values={values} intensity={intensity} isTooltipsVisible={isTooltipsVisible} />
        </div>
        <PositionControl
          visible={isRangeMode}
          setVisible={setIsRangeMode}
          setPositions={setPositions}
          isTooltipsVisible={isTooltipsVisible}
          parent={parent}
          values={values}
          setValues={setValues}
          positions={positions}
        />
        <div className={cn(styles.intensity, styles._100)}> 100% intensity</div>
        <div className={cn(styles.intensity, styles._0)}> 0% intensity</div>
      </div>
    </div>
  );
};

export default Controller;
