import React, { createContext, useEffect, useState } from 'react';
import PubNub from 'pubnub';
import { useUrlParams } from './UrlParamsHook';

const FEEL_CONNECT_PREFIX = 'mobile-client-v3-';
const UPDATE_EXPIRATION_STATE_INTERVAL_MS = 2000;

export const defaultPubnubConfig = {
  publishKey: 'pub-c-2859d4b9-7f64-428d-85f3-02aeef648325',
  subscribeKey: 'sub-c-296aa490-f663-11e5-ba5f-0619f8945a4f',
};

const uuid = `temp-client-id-${Math.floor(Math.random() * 100000)}`;
const pubnub = new PubNub({ ...defaultPubnubConfig, uuid });

const PubnubConnectionContext = createContext();

export const usePubnubConnection = () => {
  const context = React.useContext(PubnubConnectionContext);
  if (context === undefined) {
    throw new Error(
      '`usePubnubConnection` hook must be used within a `PubnubConnectionContextProvider` component',
    );
  }
  return context;
};

export const PubnubConnectionContextProvider = ({ children }) => {
  const { key } = useUrlParams();
  const [cooldownIsActive, setCooldownIsActive] = useState(false);
  const [isOnline, setIsOnline] = useState(false);
  const [expirationTime, setExpirationTime] = useState(null);
  const [isExpired, setIsExpired] = useState(false);
  const [partner, setPartner] = useState('');

  // Update device subscription and channel subscription each time
  // when the state has changed
  useEffect(() => {
    const channels = [key];

    const processChannelOnlineState = (response) => {
      const { channels } = response;
      const { occupants } = channels?.[key] || [];
      const mobile = occupants.find((o) => o?.uuid?.startsWith(FEEL_CONNECT_PREFIX));
      setIsOnline(!!mobile);
    };

    const handlePresenceEvent = (presenceEvent) => {
      const { uuid, action } = presenceEvent;
      if (!uuid.startsWith(FEEL_CONNECT_PREFIX)) {
        return;
      }
      if (action === 'join') {
        setIsOnline(true);
      } else if (action === 'leave' || action === 'timeout') {
        setIsOnline(false);
      }
    };

    const handleCooldownMode = ({ message: { cooldown } }) => {
      if (cooldown === 'on') {
        setCooldownIsActive(true);
      }
      if (cooldown === 'off') {
        setCooldownIsActive(false);
      }
    };

    const handleMetadataEvent = (metadataEvent) => {
      const { sharingEndTime } = metadataEvent?.message?.data?.custom;
      setExpirationTime(sharingEndTime);
    };

    const subscribePubNubChannels = async () => {
      pubnub.subscribe({
        channels,
        withPresence: true,
      });

      const listener = {
        presence: (presenceEvent) => {
          handlePresenceEvent(presenceEvent);
        },
        objects: (objectsEvent) => {
          handleMetadataEvent(objectsEvent);
        },
        message: (message) => {
          handleCooldownMode(message);
        },
      };
      pubnub.addListener(listener);

      // Check if FeelConnect is online
      pubnub.hereNow(
        {
          channels,
          includeUUIDs: true,
          includeState: true,
        },
        (status, response) => {
          processChannelOnlineState(response);
        },
      );

      const someTimeInPast = new Date() - 1;
      try {
        const result = await pubnub.objects.getChannelMetadata({
          channel: channels[0],
        });
        const sharingEndTime = result?.data?.custom?.sharingEndTime || someTimeInPast;
        setExpirationTime(sharingEndTime);
        const { username } = result?.data?.custom;
        setPartner(username);
      } catch (status) {
        // Pubnub Objects request failed, not data available
        // Probably invalid channel
        // Set as expired
        setExpirationTime(someTimeInPast);
      }

      return listener;
    };

    /**
     * Unsibscribe from PubNub partner channels
     * @param {array} subs - listener object and array of PubNub subscribed channels:
     *                       {subs, listener}
     */
    const unsubscribePubNubChannels = (listener) => {
      pubnub.removeListener(listener);
      pubnub.unsubscribe({
        channels,
      });
    };

    if (!key) {
      return;
    }
    const listener = subscribePubNubChannels();
    return () => {
      unsubscribePubNubChannels(listener);
    };
  }, [key]);

  setInterval(() => {
    // Check if sharing is expired
    const expired = expirationTime && new Date(expirationTime) < new Date();
    setIsExpired(expired);
  }, UPDATE_EXPIRATION_STATE_INTERVAL_MS);

  const sendPercent = (percent, position, isRangeMode) => {
    if (!key) {
      return;
    }

    const message = {
      version: 'v1',
      percent,
      position: JSON.stringify(position),
      isRangeMode,
    };

    const channel = key;
    pubnub.publish(
      {
        message,
        channel,
      },
      (status, response) => {
        // handle status, response
      },
    );
  };

  return (
    <PubnubConnectionContext.Provider
      value={{
        sendPercent,
        isOnline,
        isExpired,
        partner,
        cooldownIsActive,
      }}
    >
      {children}
    </PubnubConnectionContext.Provider>
  );
};
