import React from "react";
import styles from "./styles.module.scss";
import { translate } from "../../localization";

import Title from "../../components/Title";
import Footer from "../../components/Footer";
import ControlArea from "../../components/TouchControl";
import { usePubnubConnection } from "../../hooks/PubNubConnectionHook";

const Desktop = () => {
  const { partner } = usePubnubConnection();

  return (
    <div className={styles.desktopGrid}>
      <Title username={partner || translate("User.anonymous")} />
      <ControlArea />
      <Footer />
    </div>
  );
};

export default Desktop;
