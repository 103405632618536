import React from "react";
import styles from "./styles.module.scss";
import { translate } from "../../localization";

import { usePubnubConnection } from "../../hooks/PubNubConnectionHook";

import Title from "../../components/Title";
import Footer from "../../components/Footer";

import expiredS from "../../images/img-session-expired/img-session-expired.webp";
import expiredM from "../../images/img-session-expired/img-session-expired@2x.webp";
import expiredL from "../../images/img-session-expired/img-session-expired@3x.webp";

const DesktopExpired = () => {
  const { partner } = usePubnubConnection();
  return (
    <div className={styles.desktopGrid}>
      <Title username={partner || translate("User.anonymous")} />
      <div className={styles.expiredWrapper}>
        <div className={styles.background}>
          <div className={styles.foreground}>
            <img
              src={expiredS}
              alt="Session Expired Sadface"
              srcSet={`${expiredM} 2x, ${expiredL} 3x`}
            />
            <h1 className={styles.title}>{translate("Expired.sorry")}</h1>
            <h3 className={styles.subtitle}>{translate("Expired.expired")}</h3>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DesktopExpired;
