import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import { translate } from "../../localization";

import { usePubnubConnection } from "../../hooks/PubNubConnectionHook";

import Title from "../../components/Title";
import Footer from "../../components/Footer";
import StartButton from "../../components/StartButton";
import { useUrlParams } from "../../hooks/UrlParamsHook";

const MobileLanding = () => {
  const navigate = useNavigate();
  const { key } = useUrlParams();
  const { partner } = usePubnubConnection();

  const buttonClickHandler = () => {
    navigate(`/control/${key}`);
  };

  return (
    <div className={styles.mobileGrid}>
      <Title username={partner || translate("User.anonymous")} />
      <StartButton onClick={buttonClickHandler} />
      <Footer />
    </div>
  );
};

export default MobileLanding;
