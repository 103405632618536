import React, { useEffect, useRef, useState } from 'react';
import { takeRight } from 'lodash';
import LineGraph from 'react-line-graph';

import styles from './styles.module.scss';
import { usePubnubConnection } from '../../../../hooks/PubNubConnectionHook';
import avatar from '../../../../images/avatar.svg';
import cooldown_background from '../../../../images/cooldown_background.svg';
import { translate } from '../../../../localization';

const TIMEOUT = 100; // ms
const DOTS_AMOUNT = 40; // the number of points that form a curve

const chartPlaceholder = new Array(DOTS_AMOUNT).fill(0);

const getStrokePositionValues = (positionCode) => {
  switch (positionCode) {
    case '111': // top middle bottom
      return { maxValue: 99, minValue: 0 };
    case '110': // top middle
      return { maxValue: 99, minValue: 33 };
    case '011': // middle bottom
      return { maxValue: 66, minValue: 0 };
    case '100': // top
      return { maxValue: 99, minValue: 66 };
    case '010': // middle
      return { maxValue: 66, minValue: 33 };
    case '001': // bottom
      return { maxValue: 33, minValue: 0 };
    default:
      return {};
  }
};

const calcDotsOnSineChart =
  (index) =>
  ({ speed, min, max, mid }, idx) => {
    const { maxValue, minValue } = getStrokePositionValues(
      [Number(max), Number(mid), Number(min)].join(''),
    );

    if (!speed || !maxValue) {
      return 0;
    }
    return (
      (maxValue - minValue) * Math.sin((((index + idx) * Math.PI) / 180) * speed) +
      minValue +
      (maxValue - 0.99)
    );
  };

const Chart = ({ intensity = 0, max = true, mid = true, min = true, isRangeMode }) => {
  const [chartData, setChartData] = useState(chartPlaceholder.map(calcDotsOnSineChart(0)));
  const strokerRawData = useRef(chartPlaceholder.map(calcDotsOnSineChart(0)));
  const [circlePosition, setCirclePosition] = useState(0);
  const [recentIntensity, setRecentIntensity] = useState(chartPlaceholder);
  const counter = useRef(0);
  const { cooldownIsActive } = usePubnubConnection();

  const ref = useRef(null);
  const rangeTimerId = useRef(null);
  const intensityTimerId = useRef(null);

  useEffect(() => {
    if (rangeTimerId.current) {
      clearInterval(rangeTimerId.current);
    }

    rangeTimerId.current = setInterval(() => {
      strokerRawData.current.push({ speed: intensity, min, max, mid });
      if (strokerRawData.current.length > DOTS_AMOUNT) {
        strokerRawData.current.shift();
      }
      const mapped = strokerRawData.current.map(calcDotsOnSineChart(counter.current));
      const normalized = !intensity ? [0.00001, ...takeRight(mapped, DOTS_AMOUNT - 1)] : mapped;

      setChartData(takeRight(normalized, DOTS_AMOUNT));
      counter.current += 1;
    }, TIMEOUT);
    return () => {
      clearInterval(rangeTimerId.current);
    };
  }, [intensity, max, min, mid]);

  useEffect(() => {
    if (isRangeMode) {
      clearInterval(intensityTimerId.current);
      return;
    }

    intensityTimerId.current = setInterval(() => {
      setRecentIntensity((prev) => {
        return [0.00001, ...takeRight([...prev, intensity], DOTS_AMOUNT - 1)];
      });
    }, 100);

    // cleanup functions
    const timoutId = setTimeout(() => clearInterval(intensityTimerId.current), 4000);
    return () => {
      clearInterval(intensityTimerId.current);
      clearTimeout(timoutId);
    };
  }, [isRangeMode, intensity]);

  useEffect(() => {
    ref.current = document.querySelector('circle');
  }, []);

  useEffect(() => {
    if (ref.current) {
      console.log(ref.current.attributes[1].nodeValue);
      setCirclePosition(ref.current.attributes[1].nodeValue - 30);
    }
  }, [ref?.current?.outerHTML]);

  return (
    <div
      className={styles.chartContainer}
      style={cooldownIsActive ? { height: '100%' } : { height: '80%' }}
    >
      <div className={styles.circlePosition} style={{ right: 5, top: `${circlePosition}px` }}>
        {intensity}
      </div>
      {cooldownIsActive ? (
        <div className={styles.cooldownBlock}>
          <div className={styles.avatar}>
            <img src={avatar} alt="" />
          </div>
          <div className={styles.cooldownContainer}>
            <div className={styles.cooldownTitle}>{translate('General.CooldownTitle')}</div>
            <div className={styles.cooldownDesc}>{translate('General.CooldownDesc')}</div>
            <img className={styles.background} src={cooldown_background} alt="" />
          </div>
        </div>
      ) : (
        <div className={styles.chart}>
          {chartData.length && (
            <LineGraph
              data={isRangeMode ? chartData : recentIntensity}
              smoothing={0.75}
              strokeWidth={2}
            />
          )}
        </div>
      )}
    </div>
  );
};

export { Chart };
