import React from "react";
import { PubnubConnectionContextProvider } from "./hooks/PubNubConnectionHook";

const Providers = ({ children }) => {
  return (
    <PubnubConnectionContextProvider>
      {children}
    </PubnubConnectionContextProvider>
  );
};

export default Providers;
