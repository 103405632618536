import React from "react";
import styles from "./styles.module.scss";

import logoS from "../../images/logo-kiiroo/logo-kiiroo@3x.webp";
import logoM from "../../images/logo-kiiroo/logo-kiiroo@3x.webp";
import logoL from "../../images/logo-kiiroo/logo-kiiroo@3x.webp";
import imageS from "../../images/img-browser-toys/img-browser-toys@2x.webp";
import imageM from "../../images/img-browser-toys/img-browser-toys@2x.webp";
import imageL from "../../images/img-browser-toys/img-browser-toys@2x.webp";

import { translate } from "../../localization/index";

const Footer = () => {
  const buttonClickHandler = () => {
    window.location.href = "http://www.kiiroo.com";
  };

  return (
    <div className={styles.container}>
      <img
        className={styles.logo}
        src={logoS}
        alt="Logo"
        srcSet={`${logoM} 2x, ${logoL} 3x`}
      />
      <p>{translate("Footer.banner")}</p>
      <button onClick={buttonClickHandler}>{translate("Footer.cta")}</button>
      <img
        className={styles.image}
        src={imageS}
        alt="Toys"
        srcSet={`${imageM} 2x, ${imageL} 3x`}
      />
    </div>
  );
};

export default Footer;
