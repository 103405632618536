import cn from 'classnames';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { usePubnubConnection } from '../../../hooks/PubNubConnectionHook';
import { useUrlParams } from '../../../hooks/UrlParamsHook';
import { translate } from '../../../localization';
import { Chart } from './Chart';
import styles from './styles.module.scss';
import backIcon from '../../../images/icons-i-back-inverse.svg';

const ChartContainer = ({ intensity, positions, isRangeMode }) => {
  const { partner, isOnline } = usePubnubConnection();
  const navigate = useNavigate();
  const { key } = useUrlParams();

  const backButtonClickHandler = () => {
    navigate(`/${key}`, { replace: true });
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {isMobile && (
          <img
            src={backIcon}
            alt="Back"
            className={styles.backIcon}
            onClick={backButtonClickHandler}
          />
        )}
        <div>
          <div className={styles.partnerName}>{partner || translate('General.Anonymous')}</div>
          <div
            className={cn({
              [styles.status]: true,
              [styles.offline]: !isOnline,
              [styles.online]: isOnline,
            })}
          >
            {isOnline ? translate('General.Online') : translate('General.Offline')}
          </div>
        </div>
      </div>
      <div className={styles.chart}>
        <Chart intensity={intensity} {...positions} isRangeMode={isRangeMode} />
      </div>
    </div>
  );
};

export default ChartContainer;
