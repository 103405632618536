import React  from 'react';
import { isMobile } from 'react-device-detect';
import styles from './styles.module.scss'
import cn from "classnames"

const Touch = ({ values, intensity, isTooltipsVisible }) => {

  return (
    <div className={styles.container} style={{ left: values.x, top: values.y }}>
      {
        isTooltipsVisible && (
          <div className={ cn({
            [styles.tooltip] : true,
            [styles.mobile]: isMobile,
            [styles.desktop]: !isMobile,
          })}>
            Swipe up and down to<span className={styles.subText}> control the intensity</span>
          </div>
        )
      }
      <div className={cn({
        [styles.touch]: true,
        [styles.mobile]: isMobile,
        [styles.desktop]: !isMobile
      })}>{intensity}</div>
    </div>
  );
};

export default Touch;
