import I18n from "i18n-js";

const translationGetters = {
  en: require("./translations/en.json"),
  ru: require("./translations/ru.json"),
  es: require("./translations/es.json"),
  de: require("./translations/de.json"),
  fr: require("./translations/fr.json"),
  ja: require("./translations/ja.json"),
};

const translate = I18n.t;

// set I18n-js config
I18n.defaultLocale = "en";
I18n.fallbacks = true; // For unknown language fall back to English
I18n.translations = {
  en: translationGetters.en,
  ru: translationGetters.ru,
  es: translationGetters.es,
};

const language = window.navigator.language || I18n.defaultLocale;
const languageTag = language.split("-")[0];
I18n.locale = languageTag;

export { translate, languageTag };
