import React from "react";
import Media from "react-media";
import { Navigate } from "react-router-dom";

import { useUrlParams } from "../../hooks/UrlParamsHook";

import ControlArea from "../../components/TouchControl";

import { MOBILE_BREAKPOINT } from "../../styles/breakpoints";
import styles from "./styles.module.scss";

const MobileControl = () => {
  const { key } = useUrlParams();
  return (
    <Media query={MOBILE_BREAKPOINT}>
      {(isMobile) =>
        isMobile ? (
          <div className={styles.wrapper}>
            <ControlArea width={"100%"} height={"100%"} />
          </div>
        ) : (
          <Navigate to={`/${key}`} replace={true} />
        )
      }
    </Media>
  );
};

export default MobileControl;
