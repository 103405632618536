import React from "react";
import styles from "./styles.module.scss";
import { translate } from "../../localization/index";
import { useSmallScreen } from "../../hooks/SmallScreenHook";

import logoS from "../../images/feel-connect-logo-white/feel-connect-logo-white.webp";
import logoM from "../../images/feel-connect-logo-white/feel-connect-logo-white@2x.webp";
import logoL from "../../images/feel-connect-logo-white/feel-connect-logo-white@3x.webp";

const Title = ({ username }) => {
  const isSmallScreen = useSmallScreen();

  const titleStyles = {
    fontSize: isSmallScreen ? "26px" : "32px",
  };

  const logoStyles = {
    width: isSmallScreen ? "150px" : "200px",
  };

  return (
    <div className={styles.titleContainer}>
      <img
        src={logoS}
        alt="Logo"
        srcSet={`${logoM} 2x, ${logoL} 3x`}
        style={logoStyles}
      />
      <h1 className={styles.title} style={titleStyles}>
        {translate("Title.title")}
      </h1>
      <h3 className={styles.subtitle}>
        {username} {translate("Title.subtitle")}
      </h3>
    </div>
  );
};

export default Title;
