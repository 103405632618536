import { useState, useEffect } from "react";

const SMALL_SCREEN_HEIGHT = 614; // pixels

export function useSmallScreen() {
  const [ isSmallScreen, setSmallScreen ] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setSmallScreen(window.innerHeight < SMALL_SCREEN_HEIGHT);
    });
  }, []);

  return isSmallScreen;
}
