import React from "react";
import { Routes, Route } from "react-router-dom";
import Media from "react-media";
import "./App.scss";
import "./fonts/Lato/latofonts.css";
import "./fonts/SFProDisplay/sfprodisplayfonts.css";

import Providers from "./Providers";
import Desktop from "./routes/Desktop";
import DesktopExpired from "./routes/DesktopExpired";
import MobileLanding from "./routes/MobileLanding";
import MobileControl from "./routes/MobileControl";
import MobileExpired from "./routes/MobileExpired";

import { MOBILE_BREAKPOINT } from "./styles/breakpoints";

const indexPath = "/:key";

function App() {
  const appStyle = {
    height: window.innerHeight
  };

  return (
    <Providers>
      <div className='App' style={appStyle}>
        <div className='backbackground'></div>
        <div className='background'></div>
        <Media query={MOBILE_BREAKPOINT}>
          {(isMobile) => {
            return (
              <Routes>
                {isMobile ? (
                  <>
                    <Route path={indexPath} element={<MobileLanding />} />
                    <Route path='/expired/:key' element={<MobileExpired />} />
                  </>
                ) : (
                  <>
                    <Route path='/:key' element={<Desktop />} />
                    <Route path='/expired/:key' element={<DesktopExpired />} />
                  </>
                )}
                <Route path='/control/:key' element={<MobileControl />} />
              </Routes>
            );
          }}
        </Media>
      </div>
    </Providers>
  );
}

export default App;
