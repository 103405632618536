import React from "react";
import { translate } from "../../localization/index";
import styles from "./styles.module.scss";
import { useSmallScreen } from "../../hooks/SmallScreenHook";

const StartButton = ({ onClick }) => {
  const isSmallScreen = useSmallScreen();

  const buttonClickHandler = () => {
    onClick();
  };

  const subStyles = {
    paddingTop: isSmallScreen ? "15px" : "30px",
    paddingBottom: isSmallScreen ? "15px" : "30px",
  };

  return (
    <div className={styles.buttonWrapper} style={subStyles}>
      <button onClick={buttonClickHandler} className={styles.startButton}>
        {translate("StartButton.text")}
      </button>
    </div>
  );
};

export default StartButton;
