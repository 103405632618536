import React from "react";
import styles from "./styles.module.scss";
import { translate } from "../../localization";

import Footer from "../../components/Footer";

import expiredS from "../../images/img-session-expired/img-session-expired.webp";
import expiredM from "../../images/img-session-expired/img-session-expired@2x.webp";
import expiredL from "../../images/img-session-expired/img-session-expired@3x.webp";

const MobileExpired = () => {
  return (
    <div className={styles.mobileGrid}>
      <div className={styles.mainArea}>
        <img
          src={expiredS}
          alt="Session Expired Sadface"
          srcSet={`${expiredM} 2x, ${expiredL} 3x`}
        />
        <h1 className={styles.title}>{translate("Expired.sorry")}</h1>
        <h3 className={styles.subtitle}>{translate("Expired.expired")}</h3>
      </div>
      <Footer />
    </div>
  );
};

export default MobileExpired;
