import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import lodash from 'lodash';

import { usePubnubConnection } from '../../hooks/PubNubConnectionHook';
import { useUrlParams } from '../../hooks/UrlParamsHook';
import ChartContainer from './ChartContainer';
import Controller from './Controller';
import styles from './styles.module.scss';

const ControlArea = () => {
  const [intensity, setIntensity] = useState(0);
  const [positions, setPositions] = useState({ max: true, mid: true, min: true });
  const [isRangeMode, setIsRangeMode] = useState(true);

  const navigate = useNavigate();

  const { isExpired, sendPercent } = usePubnubConnection();
  const { key } = useUrlParams();

  useEffect(() => {
    if (!isExpired || !key) {
      return;
    }
    navigate(`/expired/${key}`);
  }, [isExpired, navigate, key]);

  const send = useCallback(
    lodash.throttle((newIntensity) => {
      sendPercent(newIntensity, positions, isRangeMode);
    }, 200),
    [sendPercent, positions, isRangeMode],
  );

  useEffect(() => {
    send(intensity);
  }, [intensity, positions, isRangeMode]);

  return (
    <div className={styles.container}>
      <ChartContainer intensity={intensity} positions={positions} isRangeMode={isRangeMode} />
      <Controller
        intensity={intensity}
        setIntensity={setIntensity}
        positions={positions}
        setPositions={setPositions}
        isRangeMode={isRangeMode}
        setIsRangeMode={setIsRangeMode}
      />
    </div>
  );
};

export default ControlArea;
