import React, { useEffect, useState } from "react"
import { isMobile } from "react-device-detect"
import cn from 'classnames'

import styles from './styles.module.scss'

const checkAvailability = (positions, position) => {
  return (position === "mid" && positions.max && positions.min)
    || (position === "max" && !positions.mid && positions.min)
    || (position === "min" && !positions.mid && positions.max)
}

const PositionControl = ({ positions, setPositions, visible, setVisible, values, setValues, parent, isTooltipsVisible }) => {
  const [ oldPositions, setOldPositions ] = useState(positions)

  useEffect(() => {
    if(visible) {
      setPositions(oldPositions)
    } else {
      setOldPositions(positions)
      setPositions({ max: true, mid: true, min: true })
    }
  }, [ visible ])


  const handleItemClick = (position) => () => {
    if (!checkAvailability(positions, position)) {
      setPositions(prev => ({ ...prev, [position]: !prev[position] }))
    }
  }

  const handleToggleBtnClick = () => {
    if (!visible && values.x > parent.getBoundingClientRect().width - 130) {
      setValues(prev => ({ ...prev, x: parent.getBoundingClientRect().width - 130 }))
    }
    setVisible(prev => !prev)
  }

  return (
    <div
      className={cn({
        [styles.container]: true,
        [styles.open]: visible,
        [styles.close]: !visible,
      })}>
      {
        isTooltipsVisible && isMobile && (
          <div className={styles.tooltip}>
            Use the side buttons to control the range
          </div>
        )
      }
      <div className={cn({
        [styles.block]: true,
        [styles.open]: visible,
        [styles.close]: !visible,
      })}>
        { Object.keys(positions).map(item =>
          <div
            key={ item }
            className={cn({
              [styles.item]: true,
              [styles.active]: positions[item],
              [styles.inactive]: !positions[item],
            })}
            onClick={ handleItemClick(item) }
          />,
        ) }
      </div>
      <div className={styles.toggleBtn} onClick={ handleToggleBtnClick } />
    </div>
  )
}

export default PositionControl
